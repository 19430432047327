.chatbot-container {
  position: relative;
  height: 500px; /* Adjust height as needed */
  width: 350px;  /* Adjust width as needed */
  border: 1px solid #ccc; /* Basic border - customize as needed */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures no overflow */
}

/* Additional custom styles can go here */


