/* Global Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4; /* Light grey background */
    color: #333; /* Dark text for contrast */
}

.App {
    text-align: center;
}

/* Navigation Bar Styles (If you have one) */
.navbar {
    background-color: #333;
    color: white;
    padding: 10px 0;
}

/* Add more global styles as needed */

