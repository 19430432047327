.home-container {
    position: relative;
    text-align: center;
    color: white;
}

.hero-image-container {
    position: relative;
}

.hero-image {
    width: 100%;
    height: auto;
    opacity: 0.7; /* Lower the image opacity to make text more legible */
}

.overlay-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    position: absolute;
    top: 20%;
    width: 100%;
    padding: 0 15px; /* Add some padding on smaller screens */
}

/* Add a media query for smaller screens */
@media (max-width: 768px) {
    .hero-image {
        opacity: 0.5; /* Increase contrast for smaller devices */
    }

    .content {
        top: 10%; /* Adjust the position of the text for smaller screens */
    }
}

